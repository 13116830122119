import React, { useCallback, useState } from "react";
import styled from "@emotion/styled";

import Menu from "./Menu";
import { useRouter } from "next/router";
import { queryParserUrl } from "shared/models/functions/query-string-helper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getSector } from "api/sector.request";

import { DefaultGroup } from "shared/components/checbox-group";
import { DefaultTextField } from "shared/components/text-field";
import Link from "next/link";
import { useTranslations } from "next-intl";

  
function Content(props: any) {
  const { onChange, value, sectorsCheckedList } = props;
  const router = useRouter();
  const context = useQueryClient();
  const filters: any = queryParserUrl(router.asPath);
  const idCity = getIdCity(filters);
  const [sectors, setData] = useState<any>([]);
  const [checkedList, setCheckedList] = useState<any>([]);
  const translations = useTranslations("realestateList");

  const { mutate, isLoading, data, error } = useMutation(
    ["list-sectors"],
    (e: any) => getSector(e),
    {
      onSuccess: ({ data }) => {
        ParseSectors(data);
      },
    }
  );

  const loadSectors = useCallback(
    (search: string) => {
      if (
        search != "" &&
        search != null &&
        data != undefined &&
        data.data != undefined
      ) {
        ParseSectors(
          data.data.filter((x) => x.description.toLowerCase().includes(search.toLowerCase())
          )
        );
      }
    },
    [data]
  );

  const ParseSectors = useCallback((data: any) => {
    setData(
      data.map((x: any) => ({
        label: x.description,
        value: x.id,
        checked: checkedList.find( (item:any) => item.id == x.id )
      }))
    );
  }, []);

  React.useEffect(() => {
    if (idCity && isLoading == false) {
      mutate({
        cityId: idCity,
      });
    }
  }, [idCity]);

  React.useEffect(() => {
    setCheckedList(sectorsCheckedList)
  }, [sectorsCheckedList] )

  if ((data == undefined || data.data == undefined) && idCity == null) {
    return (
      <Wrapper>
        <MessageResults>
          {/* <Link shallow> */}
            <a href="#locations" className="aquí_sector">{translations("sectorFilter.text1")} 
              &nbsp;<LinkStyle>{translations("sectorFilter.text2")}</LinkStyle>
            </a>
          {/* </Link> */}
        </MessageResults>
      </Wrapper>
    );
  }

  if (isLoading || data == undefined || data.data == undefined) {
    return (
      <Wrapper>
        <MessageResults>Cargando</MessageResults>
      </Wrapper>
    );
  }

  const { children, ...rest } = props;

  const handleCheckedStatus = (item: any) => {
    return value.find((l: any) => l.id == item);
  };

  return (
    <Wrapper>
      <Options>
        <ContentSearch>
          <CustomDefaultTextField
            placeholder="Buscar ..."
            name="search"
            onChange={(e) => {
              loadSectors(e.target.value);
            }}
          ></CustomDefaultTextField>
        </ContentSearch>

        <StyledGroup
          manualChecked={true}
          handleCheckedStatusCustom={handleCheckedStatus}
          columns={12}
          items={sectors}
          row={false}
          onChange={(e: any) => {
            onChange(e, data?.data);
          }}
          values={value}
          name="sector"
        />
      </Options>
    </Wrapper>
  );
}

function SectorFilters(props: any) {
  const { children, ...rest } = props;

  const Children = React.cloneElement(props.children);

  return <Menu content={<Content {...rest} />}>{Children}</Menu>;
}

export default SectorFilters;



const ContentSearch = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  background-color: #f7f6f4;
  padding: 10px 5px;
  z-index: 1000;
`;

const CustomDefaultTextField = styled(DefaultTextField)`
  &.MuiInput-root {
    padding: 5px 23px !important;
  }
`;

const MessageResults = styled.span`
  color: var(--color-blue-main);
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  width: 100%;
  display: inline-block;
`;
const LinkStyle = styled.span`
  color: var(--color-secondary);
  text-decoration: underline;
`
const Wrapper = styled.div`
  background-color: #f7f6f4;
  border-radius: 10px;
  padding: 18px 13px 13px;
  min-width: 200px;
`;

const Options = styled.div`
  width: 100%;
  display: block;
  overflow: auto;
  max-height: 200px;
`;

const StyledGroup = styled(DefaultGroup)`
  & .MuiFormControlLabel-root {
    margin-left: 0;
  }

  & .MuiCheckbox-root {
    padding: 5px;
    color: #1f3564;
  }

  & .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
`;

const getIdCity = (filters: any) => {
  if (filters.query?.locations) {
    return filters.query?.locations[0].split("&")[0].split("-")[1];
  }
  return null;
};
