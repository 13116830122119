import Head from "next/head";
import Filters from "shared/components-pages/proyectos/Filters";
import ProjectList from "shared/components-pages/proyectos/ProjectList";
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { queryParserUrl } from "shared/models/functions/query-string-helper";
import {
  parseRealestateFiltersToUrl,
  parseUrlToRealestateFilters,
  validatePriceRange,
} from "shared/models/functions/realestate-filters-helper";
import { FormConfig } from "shared/components/buy-sell-filter/util/form.util";
import { CPagesPath } from "shared/models/const/pages-path";
import useCities from "custom-hooks/use-cities.hook";

function adaptQueryParams(query: any, props: any) {
  if (typeof query.bedrooms == "string") {
    query.bedrooms = [query.bedrooms];
  }

  return query && !props.defaultFilters?.ceoDynamic
    ? parseUrlToRealestateFilters(query)
    : {};
}

function RealestateList(props: any) {
  const [countUpdateFilters, SetCountUpdateFilters] = useState(0);
  const { defaultFilters, collection } = props;
  const [title, setTitle] = useState<string | null>(null);
  const { data: cities } = useCities();

  const router = useRouter();
  const { query } = queryParserUrl(router.asPath);
  const defaultState = {
    ...FormConfig.initialValues,
    ...defaultFilters.filters,
    ...adaptQueryParams(query, props),
  };

  const [queryFilters, setFilters] = useState(defaultState);

  const onFieldChange = (field: string, value: any) => {
    const newFilters: any = { ...queryFilters, [field]: value };
    setFilters(newFilters);
  };

  const handleFiltersReset = () => {
    setFilters(defaultState);
  };

  const onBusinessChange = (e: any) => {
    let value;

    if (e.target) {
      value = e.target.value;
    } else if (typeof e === "string") {
      value = e;
    }

    if (value == "sale") {
      setTitle("Quiero Comprar");
    } else {
      setTitle("Quiero Alquilar");
    }

    onFieldChange("businessTypes", value);
  };

  const onTypeChange = (e: any) => {
    const value: string = e.target.value;
    const typeProperty: Array<string> = [...queryFilters.typeProperty];

    if (typeProperty.includes(value)) {
      const index = typeProperty.indexOf(value);
      typeProperty.splice(index, 1);
    } else {
      typeProperty.push(value);
    }

    onFieldChange("typeProperty", typeProperty);
  };

  const onParkingLotsChange = (e: any) => {
    const value = e.target.value;

    if (value === queryFilters.parkings) {
      onFieldChange("parkings", "");
    } else {
      onFieldChange("parkings", value);
    }
  };

  const onBedroomsChange = (e: any) => {
    const value: string = e.target.value;
    const bedrooms: Array<string> = [...queryFilters.bedrooms];

    if (bedrooms.includes(value)) {
      const index = bedrooms.indexOf(value);
      bedrooms.splice(index, 1);
    } else {
      bedrooms.push(value);
    }
    onFieldChange("bedrooms", bedrooms);
  };

  const onBathroomsChange = (e: any) => {
    const value = e.target.value;

    if (value === queryFilters.bathrooms) {
      onFieldChange("bathrooms", "");
    } else {
      onFieldChange("bathrooms", value);
    }
  };

  const onSectorsChange = (e: any, data: any[]) => {
    const value: any = e.target.value;
    const newSector = data.find((x: any) => x.id == value);

    let sector: Array<string> = queryFilters.sector.map((x: any) => ({
      id: +x.id,
      description: x.description,
    }));

    if (e.target.checked == false) {
      sector = sector.filter((x: any) => x.id != +value);
    } else {
      sector.push(newSector);
    }
    setFilters({
      ...queryFilters,
      sector,
    });
  };

  const onCurrencyChange = (currencyType: any) => {
    setFilters({
      ...queryFilters,
        currencyType,
        amountMin: "",
        amountMax: "",
    });
  };

  const onAmountChange = (eventValue: string, field: "max" | "min") => {
    const value = eventValue ? +eventValue : null;
    let amountMax 
    let amountMin
    let currencyType
    if(typeof field == 'undefined' && eventValue?.length === 3){
      amountMax = +eventValue[1];
      amountMin = +eventValue[0];
      currencyType = eventValue[2];
      console.log({amountMax, amountMin});
    }else{
      amountMax = field === "max" ? value : queryFilters.amountMax;
      amountMin = field === "min" ? value : queryFilters.amountMin;
    }

    setFilters({
      ...queryFilters,
      currencyType,
      amountMax,
      amountMin,
    });
  };

  const onOrderByChange = (e: string, orderBy: string) => {
    const filters =  { ...queryFilters, orderBy, recent: 1 };

    if (orderBy === "recent") {
      delete filters.orderBy
    }else{
      delete filters.recent
    }

    setFilters({ ...filters });
  };

  const handleAdvancedFilters = (values: any) => {
    setFilters({ ...queryFilters, ...values });
  };

  const onLocationsChange = (e: any, value: any) => {
    const locations: Array<any> = value;
    if (locations.length == 0) {
      setFilters({ ...queryFilters, sector: [], locations });
      return;
    }
    onFieldChange("locations", locations);
  };

  useEffect(() => {
    const url = router.asPath.split("/");

    if (
      url.length > 3 &&
      router.asPath.includes("/propiedades/comprar") &&
      props.defaultFilters?.ceoDynamic == false
    ) {
      const query = parseRealestateFiltersToUrl(queryFilters);
      const newUrl = "/propiedades/comprar" + "?" + query;
      router.replace(newUrl, undefined, { shallow: true });
    }

    if (url.length <= 3 && !props.defaultFilters?.ceoDynamic) {
      const query = parseRealestateFiltersToUrl(queryFilters);
      const newUrl = window.location.pathname + "?" + query;
      if(router.asPath !== newUrl) {
        router.replace(newUrl, undefined, { shallow: true });
      }
    }
    if (
      props.defaultFilters?.ceoDynamic &&
      ((url.length == 6 && countUpdateFilters == 2) ||
        (url.length == 5 && countUpdateFilters == 2) ||
        (url.length == 4 && countUpdateFilters == 1) ||
        (url.length == 3 && countUpdateFilters == 1))
    ) {
      queryFilters.locations = cities?.filter(
        (city) =>
          city.description.toLowerCase() ==
          queryFilters.cityName?.replaceAll("-", " ")?.toLowerCase()
      );
      // queryFilters.cities =
      delete queryFilters.cityName;
      delete queryFilters.sectorName;
      const query = parseRealestateFiltersToUrl(queryFilters);

      const newUrl = CPagesPath.es.properties + "?" + query;
      router.replace(newUrl, undefined, { shallow: false });
    }

    SetCountUpdateFilters((prevCount) => prevCount + 1);
  }, [queryFilters]);

  return (
    <>
      {title && (
        <Head>
          <title>{title}</title>
        </Head>
      )}
      <Filters
        filters={queryFilters}
        onBusinessChange={onBusinessChange}
        onTypeChange={onTypeChange}
        onParkingLotsChange={onParkingLotsChange}
        onBedroomsChange={onBedroomsChange}
        onSectorsChange={onSectorsChange}
        onBathroomsChange={onBathroomsChange}
        handleAdvancedFilters={handleAdvancedFilters}
        onCurrencyChange={onCurrencyChange}
        onAmountChange={onAmountChange}
        onLocationsChange={onLocationsChange}
        collection={collection}
        handleFiltersReset={handleFiltersReset}
      />
      <ProjectList
        {...defaultFilters}
        filters={queryFilters}
        isCollection={collection}
        orderByFiltersHandler={onOrderByChange}
      />
    </>
  );
}

export default RealestateList;
