import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "@emotion/styled";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";

import { getRealestate } from "api/realestate.request";
import { realestateFiltersAdapter } from "shared/models/adapters/realestate.adapter";
import PropertyList from "./PropertyList";
import { IRealestateResponseData } from "shared/models/interfaces/response/realestate.interface";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import ResultFilterBar from "./ResultFilterBar";
import { CPropertyTypes } from "shared/models/const/property-types";
import { titleDynamicProperties } from "shared/models/functions/title-dinamic-properties.helper";
import OrderBy from "@components/orderby-filter";
import { usePropertiesLoading } from "store/properties-loading";
import Layout from "@components/layout";

export interface IProjectList {
  refRequest: string;
  detailPath: (x: IRealestateResponseData) => string;
  filters?: any;
  isCollection?: boolean;
  ceoDynamic?: boolean;
  orderByFiltersHandler?: any;
}
export default function ProjectList(props: IProjectList) {
  const [resultsFilters, setResultsFilters] = useState<any>({});
  const { loadingRequest, setLoadingPropertyAction, clearPropertyAction } =
    usePropertiesLoading();
  const [haveResultsFilters, setHaveResultsFilters] = useState<boolean>(false);
  const { filters } = props;
  const translations = useTranslations("realestateList");
  const router = useRouter();
  const queryClient = useQueryClient();
  const locationUrl = useRef(() => validateUrlAsKey(router.asPath));
  const [enableReq, setEnableReq] = useState(false);

  const request = (params: any) => {
    const { pageParam = 1 } = params;
    const newFilters: any = realestateFiltersAdapter({
      ...filters,
      page: pageParam,
    });

    if (newFilters.sector) {
      delete newFilters.city;
    }
    // @ts-ignore

    return getRealestate(newFilters);
  };

  //TODO: check bug where users scrolling to reach page two
  //and changing a filter results in page=1 fetching once
  //and page=2 getting fetched several times.
  const { data, fetchNextPage, isLoading, hasNextPage, isError, isSuccess } =
    useInfiniteQuery([locationUrl.current()], request, {
      getNextPageParam: (lastPage, pages: any) => {
        if (lastPage.meta.last_page == lastPage.meta.current_page) {
          return false;
        }

        return pages.length + 1;
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      //ignore paramless urls and
      enabled: router.asPath.includes("?") && enableReq,
    });

  const getMore = () => {
    setLoadingPropertyAction(true);
    const scroll = window.scrollY * 1.9 - window.scrollY;
    window.scrollTo({
      top: scroll,
      behavior: "smooth",
    });
    if (!loadingRequest && hasNextPage) {
      fetchNextPage().then(() => {
        setLoadingPropertyAction(false);
      });
    }
  };
  useEffect(() => {
    locationUrl.current = () =>
      validateUrlAsKey(location.pathname + location.search);
    setEnableReq(true);
  }, []);

  useEffect(() => {
    const count: any = data?.pages[0]?.meta;
    setResultsFilters({
      cities: filters?.locations.map((location: any) => location?.description),
      sector: filters?.sector.map((sector: any) => sector?.description),
      typeProperty: filters?.typeProperty.map((property: any) => {
        if (typeof property == "object") {
          return property?.name;
        }
        return CPropertyTypes.find((c) => c.slug === property)?.name;
      }),
      count: count?.total,
    });

    if (
      resultsFilters?.cities?.length > 0 ||
      resultsFilters?.sector?.length > 0 ||
      resultsFilters?.typeProperty?.length > 0
    ) {
      setHaveResultsFilters(true);
    } else {
      setHaveResultsFilters(false);
    }
  }, [filters, data]);

  useEffect(() => {
    const handleRouteChange = async (forwardPath: string) => {
      const validForwardPath = validateUrlAsKey(forwardPath);
      const currentPath = locationUrl.current();
      const prevPath = sessionStorage.getItem("prevPath");
      const comingFromDetailPage =
        prevPath?.startsWith("/propiedad/") ||
        prevPath?.startsWith("/collection/propiedad/");
      const goingToDetailPage =
        forwardPath.startsWith("/propiedad/") ||
        forwardPath.startsWith("/collection/propiedad/");

      if (!comingFromDetailPage && !goingToDetailPage) {
        //Prevent deleting current path
        if (validForwardPath !== currentPath)
          queryClient.removeQueries([currentPath]);

        //Going away and not just changing to the same url.
      } else if (!goingToDetailPage && validForwardPath !== currentPath) {
        queryClient.removeQueries([currentPath]);
      }

      //Remove any other queries that do not match the current URL
      queryClient.removeQueries({
        predicate: ({ queryKey }) => {
          const key = queryKey[0];
          const result =
            typeof key === "string" &&
            (key.startsWith("/propiedades") ||
              key.startsWith("/collection/propiedades")) &&
            key !== currentPath;
          return result;
        },
      });
    };
    router.events.on("routeChangeStart", handleRouteChange);
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events]);

  return (
    <Layout showFooter={!hasNextPage}>
      <Wrapper id="property-list">
        <div className="container">
          <SearchResult>
            {props?.ceoDynamic && <Title>{titleDynamicProperties(router)}</Title>}
            {props.ceoDynamic != true && (
              <>
                {!haveResultsFilters ? (
                  <Title>
                    {translations(
                      props.isCollection ? "titleCollection" : "title"
                    )}
                  </Title>
                ) : (
                  <ResultFilterBar
                    resultsFilters={resultsFilters}
                    isLoading={isLoading}
                    filters={filters}
                  />
                )}
              </>
            )}
          </SearchResult>
          <OrderBy handle={props.orderByFiltersHandler} />
          <PropertyList
            isLoading={isLoading}
            getMore={getMore}
            data={data}
            hasNextPage={hasNextPage}
            variant={isError ? "error" : undefined}
            isSuccess={isSuccess}
            detailPath={props.detailPath}
          />
        </div>
      </Wrapper>
    </Layout>

  );
}

let lastValidUrl = "";
const validUrlCache = new Map<string, string>();

const validateUrlAsKey = (url: string) => {
  if (validUrlCache.has(url)) return validUrlCache.get(url);
  const splitUrl = url.split("?");
  const params = new URLSearchParams(splitUrl[1]);
  const amountMax = params.get("amountMax");
  const amountMin = params.get("amountMin");

  if (!splitUrl[1]) return splitUrl[0];

  if (!amountMax && !amountMin) {
    params.delete("currencyType");
  }

  // why is this here?
  //  else if (amountMax && amountMin) {
  //   if (+amountMax < +amountMin) {
  //     return lastValidUrl;
  //   }
  // }

  lastValidUrl = splitUrl[0] + "?" + decodeURIComponent(params.toString());
  validUrlCache.set(url, lastValidUrl);

  return lastValidUrl;
};

const Wrapper = styled.section`
  width: 100%;
  background-color: var(--white);
  font-size: 0.9rem;
  padding: 50px 0px;

  @media screen and (max-width: 1299px) {
    font-size: 0.8rem;
  }
`;

const SearchResult = styled.section``;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.9rem;
  padding-top: 30px;
  margin-bottom: 60px;
  color: var(--color-blue-main);
`;
