export const titleDynamicProperties = (router: any) => {
  const params = router.asPath.split('?')[0].split("/")
  if (router.asPath.match(/^.*[+:q%22]+.*$/)) {
    return `Resultados de búsqueda`
  }
  else if (params.length == 6) {
    return `${params[2]} de ${params[3]}s en ${params[5].replaceAll('-', " ")}, ${params[4].replaceAll('-', " ")} `
  }
  else if (params.length == 5) {
    return `${params[2]} de ${params[3]}s en ${params[4].replaceAll('-', " ")}`
  }
  else if (params.length == 4) {
    return `${params[2]} de ${params[3]}s`
  }
  else if (params.length == 3 ) {
    return `Propiedades en ${params[2]}`
  }
}